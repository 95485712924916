import {lazy} from 'react';
import { RouteObject } from "./interfaces";

const ITSupportRequests = lazy(() => import('components/common/it-requests/it-support-requests'));
const ITBusinessRequests = lazy(() => import('components/common/it-requests/it-business-requests'));

const IT_REQUESTS: RouteObject[] = [
    {path: '/it-requests/support', element: ITSupportRequests},
    {path: '/it-requests/business', element: ITBusinessRequests}
];

export default IT_REQUESTS;