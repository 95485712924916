import * as CustomIcons from "../CustomIcons";

import SegmentIcon from "@mui/icons-material/Segment";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import ListIcon from "@mui/icons-material/List";
import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InsertChartOutlinedSharpIcon from "@mui/icons-material/InsertChartOutlinedSharp";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import AssignmentTurnedInOutlined from "@mui/icons-material/AssignmentTurnedInOutlined";
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

import {
	ExclamationSquare,
	BoundingBoxCircles,
	FolderCheck,
	GraphUp,
	Cloud,
	Archive,
	Easel,
	ClipboardCheck,
	CardChecklist,
	Router,
	Building,
} from '../ReactBootstrapIcons';
import { PeopleIcon } from '../Icons';
import { LinkItem } from './types';
import { ContactSupportOutlined, ManageHistorySharp, SystemUpdateAlt } from '@mui/icons-material';
import InsertChartOutlinedSharp from '@mui/icons-material/InsertChartOutlinedSharp';

function getConnectivityLinks(): LinkItem[] {
	return [
		{ title: 'Home' },
		{
			title: 'Operations',
			items: [
				{ label: 'Alarms', icon: ExclamationSquare },
				{
					label: 'Tickets',
					icon: FolderCheck,
					items: [
						{ label: 'Legacy Tickets', icon: FolderCheck, path: '/tickets' },
						{ label: 'Network Tickets', icon: FolderCheck, path: '/network-tickets' },
						{ label: 'Data Centre Tickets', icon: FolderCheck, path: '/dc-tickets' },
					],
				},
				{ label: 'Tasks', icon: CardChecklist },
				{label: 'Network Configuration', icon: ManageHistorySharp},
				{
					label: 'Reports',
					icon: GraphUp,
					items: [
						{ label: 'Insights', icon: GraphUp },
						{ label: 'Supplier', icon: GraphUp, path: '/tickets/reports/supplier' },
					],
				},
			],
		},
		{
			title: 'Diagrams',
			items: [
				{ label: 'Topology', icon: BoundingBoxCircles },
				{ label: 'WIOCC IP Core', icon: Cloud, path: '/wioccipcore' },
				{ label: 'Service Diagrams', icon: CustomIcons.Bezier2, path: '/diagrams' },
				{ label: 'Master Architecture', icon: CustomIcons.Diagram3, path: '/masterarchitecture' },
				{ label: 'BIDSD Diagrams', icon: CustomIcons.Bezier2, path: '/diagrams/bidsd' },
			],
		},
		{
			title: 'Engineering',
			items: [
				{ label: 'Links', icon: HubOutlinedIcon },
				{
					label: 'Stock',
					icon: Archive,
					items: [
						{ label: 'Stock Dashboard', icon: Inventory2OutlinedIcon, path: '/stock' },
						{ label: 'Allocation', icon: InventoryOutlinedIcon, path: '/stock/stock-dashboard' },
						{ label: 'BOMS', icon: ListIcon, path: '/boms/new' },
						{ label: 'WPNs Report', icon: SummarizeOutlinedIcon, path: '/stock/wpns-report' },
					],
				},
				{
					label: 'Programmes',
					icon: ClipboardCheck,
					items: [
						{ label: 'Programmes', icon: ClipboardCheck },
						{ label: 'Projects', icon: SegmentIcon },
					],
				},
				{
					label: 'PoPs',
					icon: Building,
					items: [
						{ label: 'PoPs', icon: Building },
						{ label: 'Log On-site', icon: DomainAddOutlinedIcon, path: '/pop_audit' },
						{ label: 'Find Engineers', icon: SearchOutlinedIcon, path: '/find-engineers' },
					],
				},
				{
					label: 'Capacity',
					icon: Easel,
					items: [
						{ label: 'Capacity Planning', icon: InventoryOutlinedIcon, path: '/capacity' },
						{ label: 'Supplier Reports', icon: InsertChartOutlinedSharp, path: '/capacity/supplier-reports'},
						{ label: 'Capacity Reports', icon: InsertChartOutlinedSharpIcon, path: '/capacity/reports' }
					],
				},
				{ label: 'Equipment', icon: Router },
			],
		},
		{
			title: 'Commercial',
			items: [
				{ label: 'SOFS', icon: AssignmentTurnedInOutlined },
				{ label: 'Client Services', icon: AssignmentTurnedInOutlined, path: '/services' },
				{ label: 'POs', icon: AssignmentTurnedInOutlined },
				{ label: 'Clients', icon: PeopleIcon, path: '/client-groups?group=1' },
        {
          label: "Sales Report",
          icon: AssessmentOutlinedIcon,
          path: "/sales/reports",
          items: [
            {
              label: "Home",
              icon: EqualizerOutlinedIcon,
              path: "/sales/reports",
            },
          ],
        },
			],
		},
		{
			title: 'IT Requests',
			items: [
				{label: 'Support Requests', icon: ContactSupportOutlined, path: '/it-requests/support'},
				{label: 'Business Requests', icon: SystemUpdateAlt, path: '/it-requests/business'}
			]
		},
		{ title: 'Service Configurator', path: '/configurator' },
	];
}
export default getConnectivityLinks;
