import React, { useContext } from "react";
import { ToastProvider } from "react-toast-notifications";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Check from "@mui/icons-material/Check";
import Remove from "@mui/icons-material/Remove";
import Link from "@mui/material/Link";

import connectivityLogo from "../../../images/Hub Logo 2022.png";
import oadcLogo from "../../../images/OADC Logo Master No Tag May 2022.png";
// import SearchField from './navbar/search-field';
import Context from "store/context";

import { useLocation, useHistory } from "react-router-dom";
import { ProfileType } from "store/interfaces";
import { SWITCH_PROFILE } from "store/storeUtil";

import getConnectivityLinks from "./connectivity-menu";
import getOadcLinks from "./oadc-menu";
import getCommonLinks from "./common-menu";
import MenuItems from "./menu-items";
import QRButton from "../QR/QRButton";
import useSharedAccounts from "./useSharedAccounts";
import { fetchJsonFromApiJsonResponse } from "../FetchFunctions";

function MyNavBar() {
  const { globalState, globalDispatch } = useContext(Context);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const inConnectivity = globalState.profile === ProfileType.CONNECTIVITY;
  const { pathname } = useLocation();
  const history = useHistory();

  const isActive = (link: string) => {
    // return link.includes(pathname?.substring(1));
    return link === pathname?.substring(1);
  };

  const { showModals, onNetsuiteMenuClick } = useSharedAccounts({
    globalState,
    globalDispatch,
  });

  // useEffect(() => {
  // 	// history.replace('/');
  // 	console.log('globalState.profile', globalState.profile);
  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [globalState.profile]);

  const open = Boolean(anchorElNav);

  function showLogo() {
    return (
      <Box
        sx={{
          display: "flex",
          height: "45px",
          mx: { xs: "auto", lg: 0 },
        }}
      >
        <img
          src={connectivityLogo}
          alt="Logo"
          height="45px"
          width="181px"
          hidden={!inConnectivity}
          style={{
            objectFit: "contain",
            aspectRatio: 3 / 2,
          }}
        />
        <img
          src={oadcLogo}
          alt="Logo"
          height="45px"
          width="181px"
          hidden={inConnectivity}
          style={{
            objectFit: "contain",
            aspectRatio: 3 / 2,
          }}
        />
      </Box>
    );
  }

  const links =
    globalState.profile === ProfileType.OADC
      ? getOadcLinks()
      : //comment
        getConnectivityLinks();

  const onLogout = () => {
    globalDispatch({ type: "LOGOUT" });
    fetchJsonFromApiJsonResponse(`logout`)
      .then((_res) => {
        fetch(_res.redirect, { mode: "no-cors" }).then((resp) => {});
        globalDispatch({ type: "LOGOUT" });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <ToastProvider>
      <AppBar
        position="sticky"
        sx={{
          "&>*": {
            fontStyle: "Montserrat-Medium",
          },
        }}
      >
        <Toolbar>
          <Box sx={{ display: { xs: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          {showLogo()}

          <Box sx={{ display: { xs: "flex", lg: "none" } }}>
            <Menu
              id="menu-appbar"
              className="nav-menu"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", lg: "none" },
              }}
            >
              {[
                ...links,
                ...getCommonLinks(globalState, onNetsuiteMenuClick),
              ].map((props, i) => {
                return (
                  <MenuItems
                    inMobile
                    key={`${props.title}-${i}`}
                    {...props}
                    isActive={isActive}
                    userDetails={globalState.userDetails}
                  />
                );
              })}
            </Menu>
            <div className="nav-item scanner-nav-item">
              <QRButton
                currentPath={pathname}
                window_width={window.innerWidth}
                otherContent={undefined}
              />
            </div>
            {/* <SearchField /> */}
          </Box>

          <Box
            sx={{
              mx: "auto",
              flexWrap: "wrap",
              display: { xs: "none", lg: "flex" },
            }}
          >
            {[
              ...links,
              ...getCommonLinks(globalState, onNetsuiteMenuClick),
            ].map((props, i) => (
              <MenuItems
                key={`${props.title}-${i}`}
                {...props}
                isActive={isActive}
                userDetails={globalState.userDetails}
              />
            ))}

            <div className="nav-item scanner-nav-item my-auto">
              <QRButton
                currentPath={pathname}
                window_width={window.innerWidth}
                otherContent={undefined}
              />
            </div>

            {/* <Box
							sx={{
								mx: {
									xs: 0,
									md: 3,
									lg: 4,
								},
							}}
						>
							<SearchField />
						</Box> */}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenUserMenu}
                size="small"
                sx={{
                  p: 0,
                  ml: 2,
                }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  color="primary"
                  alt={globalState.userDetails.name ?? ""}
                  src="/static/images/avatar/2.jpg"
                  sx={{ width: 30, height: 30, bgcolor: "secondary" }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{
                mt: "45px",
                fontFamily: "Montserrat-Regular",
              }}
              id="menu-appbar"
              className="nav-menu"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  fontFamily: "Montserrat-Regular",
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
            >
              <MenuItem key="email" onClick={handleCloseUserMenu}>
                <Typography textAlign="center">
                  {globalState.userDetails.email}
                </Typography>
              </MenuItem>

              <Divider />

              {["OADC", "Connectivity"].map((profile) => (
                <MenuItem
                  key={profile}
                  onClick={() => {
                    globalDispatch({
                      type: SWITCH_PROFILE,
                      profile: profile.toLowerCase(),
                    });
                    history.replace(pathname);
                    handleCloseUserMenu();
                  }}
                >
                  <ListItemIcon>
                    {profile.toLowerCase() === globalState.profile ? (
                      <Check />
                    ) : (
                      <Remove />
                    )}
                  </ListItemIcon>
                  <Typography textAlign="center">{profile}</Typography>
                </MenuItem>
              ))}

              <Divider />

              <MenuItem onClick={handleCloseUserMenu}>
                <Link
                  href="#logout"
                  fontFamily="Montserrat-Regular"
                  underline="hover"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onLogout();
                  }}
                >
                  Sign Out
                </Link>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      {showModals()}
    </ToastProvider>
  );
}

export default React.memo(MyNavBar);
// export default MyNavBar;
