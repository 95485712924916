import { useReducer } from 'react';
import { postDataToApiJsonResponse } from '../components/common/FetchFunctions';
import { getEmptyGlobalState, type GlobalState, type GlobalStateValues } from './interfaces';
import { SWITCH_PROFILE, UPDATE_POP_AUDIT_ID, UPDATE_POP_AUDIT_QUESTIONS } from './storeUtil';

export interface GlobalStateWithPopAuditQuestions extends GlobalState {
	pop_audit_questions?: any[] | null;
	pop_audit_id?: number | null;
	pop_audit_log_id?: number | null;
}

const reducer = (state: GlobalStateWithPopAuditQuestions, action: Record<string, any>) => {
	switch (action.type) {
		case 'LOGIN':
			return {
				...state,
				isLoggedIn: true,
			};
		case 'LOGOUT':
			sessionStorage.clear();
			localStorage.clear();
			return {
				...state,
				isLoggedIn: false,
				userDetails: {
					'name': null,
					'email': null,
					'netsuite_id': -1,
					'isShiftWorker': null,
					'using_shared_netsuite_account': null,
					'shared_netsuite_account': null,
					'jobTitle': null,
					'PRTG_GRAPHS_API': '',
					'PRTG_NETSUITE_CREDENTIALS': '',
				},
				priorityDict: { 'port': {} },
				theme: 'light',
				filterDetails: {
					filterState: {},
					filterLabels: {},
					availableFilters: [],
				},
				fontSize: 13,
				window_height: window.outerHeight,
				window_width: window.outerWidth,
			};
		case 'SETUSER':
			postDataToApiJsonResponse('setUserNetsuiteDetails', action.userDetails).catch(error => {
				console.error('Error:', error);
			});
			return {
				...state,
				userDetails: action.userDetails,
			};
		case 'SETPRIORITY':
			return {
				...state,
				priorityDict: action.priorityDict,
			};
		case 'SETTHEME':
			return {
				...state,
				theme: action.theme,
			};

		case 'SETFILTERS':
			return {
				...state,
				filterDetails: action.filterDetails,
			};
		case 'SETFONTSIZE':
			return {
				...state,
				fontSize: action.fontSize,
			};
		case 'SETWINDOWDIMENSIONS':
			return {
				...state,
				window_height: action.window_height,
				window_width: action.window_width,
			};
		case UPDATE_POP_AUDIT_QUESTIONS:
			if (action.payload) {
				const { questions, id } = action.payload;
				state.pop_audit_questions = questions;
				state.pop_audit_id = id;
			} else {
				state.pop_audit_questions = null;
				state.pop_audit_log_id = null;
				state.pop_audit_id = null;
			}

			return state;
		case UPDATE_POP_AUDIT_ID:
			state.pop_audit_log_id = action.payload;
			return state;
		case SWITCH_PROFILE:
			state.profile = action.profile; //state.profile === ProfileType.CONNECTIVITY ? ProfileType.OADC : ProfileType.CONNECTIVITY;
			return { ...state };
		default: {
			return state;
		}
	}
};

const useGlobalState = (): GlobalStateValues => {
	const persistedGlobalState = sessionStorage.getItem('globalState');
	const _globalState: GlobalState = persistedGlobalState ? JSON.parse(persistedGlobalState) : getEmptyGlobalState();
	const [globalState, globalDispatch] = useReducer(reducer, _globalState);

	return { globalState, globalDispatch };
};

export default useGlobalState;
