import React, { lazy, Suspense, useState, useContext } from 'react';
import { PrivateRoute } from './components/common/PrivateRoutes';
import { Route, Switch } from 'react-router-dom';
import GlobalStateProvider from './store/GlobalStateProvider';
import MyLoader from './components/common/MyLoader';

import operationsRoutes from './Routes/operations';
// import HubMuiThemeProvider from 'theme/mui-theme-provider';
import appRoutes from 'departments/routes';
// import EQUIANO_ROUTES from 'modules/subsea-react-app/src/routes';
import EQUIANO_ROUTES from './equiano/routes';
import NewTicketDialog from 'operations/ticketing/network-tickets/it-ticket-dialog';

const HomePage = lazy(() => import('./components/home/HomePage'));
const LoginPage = lazy(() => import('./components/LoginPage'));
const WIOCCIPCorePage = lazy(() => import('./components/topology/WIOCCIPCorePage'));
const NetSuitePage = lazy(() => import('./components/netsuite/NetSuitePage'));
// const VendorBillPage = lazy(() => import('./components/netsuite_poc/VendorBillPage'));
// const ClientInvoicePage = lazy(() => import('./components/netsuite_poc/ClientInvoicePage'));
// const InvoicePage = lazy(() => import('./components/netsuite_poc/InvoicePage'));
const TasksPage = lazy(() => import('tasks/TasksPage'));
const NetSuiteTaskPage = lazy(() => import('tasks/NetSuiteTaskPage'));
const NotFoundPage = lazy(() => import('./components/common/NotFoundPage'));

const AllPOsPage = lazy(() => import('./components/POS/AllPOsPage'));
// const SinglePOPage = lazy(() => import('./components/POS/SinglePOPage'));

window.Buffer = window.Buffer || require('buffer').Buffer;

function App() {
	const [itSupportButtonPositions, setITButtonPositions] = useState({ x: 15, y: 10 });

	return (
		<GlobalStateProvider>
			<div
				style={{ height: '100%' }}
				onDragOver={e => e.preventDefault()}
				onDrop={e => {
					setITButtonPositions({ x: e.clientX, y: e.clientY });
				}}
			>
				{/* <HubMuiThemeProvider> */}
				<Suspense fallback={<MyLoader />}>
					<Switch>
						<PrivateRoute path="/" exact component={HomePage} />
						<Route path="/login" component={LoginPage} />
						{/* <PrivateRoute path="/oadc" exact component={HomePage} /> */}
						<PrivateRoute path="/home" exact component={HomePage} />
						<PrivateRoute path="/wioccipcore" component={WIOCCIPCorePage} />

						{operationsRoutes.map(({ path, element }, i) => {
							return <PrivateRoute key={i} path={path} component={element} />;
						})}

						{appRoutes.map(({ path, element }, i) => {
							return <PrivateRoute key={i} path={path} component={element} />;
						})}

						{EQUIANO_ROUTES.map(({ path, element }, i) => {
							return <PrivateRoute key={i} path={path} component={element} />;
						})}

						<PrivateRoute path="/netsuite" component={NetSuitePage} />

						{/* <PrivateRoute path="/vendorbill" component={VendorBillPage} />
					<PrivateRoute path="/clientinvoice" component={ClientInvoicePage} />
					<PrivateRoute path="/invoice" component={InvoicePage} /> */}

						{/* TASKS */}
						<PrivateRoute path="/tasks" exact component={TasksPage} />
						<PrivateRoute path="/tasks/:assigned_to" component={TasksPage} />
						<PrivateRoute path="/task/:task_id" component={NetSuiteTaskPage} />

						{/* <PrivateRoute path="/pos/:po" component={SinglePOPage} />
						 */}
						<PrivateRoute path="/pos" component={AllPOsPage} />

						<Route
							path="/externalnetsuite"
							component={() => {
								window.location.replace('https://3398896.app.netsuite.com/app/login/secure/enterpriselogin.nl');
								return null;
							}}
						/>
						<Route component={NotFoundPage} />
					</Switch>
				</Suspense>
				{/* </HubMuiThemeProvider> */}
				
				{<NewTicketDialog x={itSupportButtonPositions.x} y={itSupportButtonPositions.y} />}
			</div>
		</GlobalStateProvider>
	);
}

export default App;
