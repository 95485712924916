import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import Context from 'store/context';
import { ProfileType } from 'store/interfaces';

const PageTitle = ({ title = 'WIOCC | HUB', metaTags = [], prependWioccHub = true }) => {
	const { globalState } = useContext(Context);
	const inConnectivity = globalState.profile === ProfileType.CONNECTIVITY;

	return (
		<Helmet>
			<title>
				{prependWioccHub ? `${inConnectivity ? 'WIOCC' : 'OADC'} HUB | ` : ''}
				{title}
			</title>
			{metaTags && metaTags.map(({ name, content }) => <meta name={name} content={content} />)}
		</Helmet>
	);
};

export default PageTitle;
