import { GlobalState } from 'store/interfaces';
import { NetworkTicket } from '../interfaces';
import DOMPurify from 'dompurify';
import { NetworkTicketVariant, ClientNetworkTicket, SupplierNetworkTicket, ParentNetworkTicket } from './interfaces';
import { makeStyles } from '@mui/styles';

type UserDetails = GlobalState['userDetails'];

interface Props {
	network: NetworkTicket[];
	clientTickets: ClientNetworkTicket[];
	supplierTickets: SupplierNetworkTicket[];
	userDetails: UserDetails;
}

export const useMuiTextFieldStyles = makeStyles({
	root: {
		'& .MuiOutlinedInput-notchedOutline': {
			border: '1px solid lightgrey !important'
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			border: '1px solid lightgrey !important'
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: '1px solid lightgrey !important'
		},
		'&:not(:focus) .MuiOutlinedInput-notchedOutline': {
			border: '1px solid lightgrey !important'
		}
	},
});

export const getCrossSiteSafeHtml = (htmlCandidateString: string) => {
	const sanitizedHtml = DOMPurify.sanitize(htmlCandidateString);
			return { __html: sanitizedHtml };
}

const ticketIsAssignedToUser = (tt: NetworkTicketVariant, userDetails: UserDetails) => {
	return tt.assigned_to === userDetails.name || tt.assigned_to_email === userDetails.email;
};

export const aggregateNetworkTickets = ({ supplierTickets, clientTickets, network, userDetails }: Props) => {
	const networkTickets: ParentNetworkTicket[] = [];
	const myTickets: (NetworkTicketVariant | ParentNetworkTicket)[] = [];

	const networkTicketIndices: Record<NetworkTicket['ticket_id'], number> = {};
	network.reduce((indices, ticket, index) => {
		const parentNetworkTicket = { ...ticket, supplierTickets: [], clientTickets: [] };
		networkTickets.push(parentNetworkTicket);

		if (ticketIsAssignedToUser(ticket, userDetails)) {
			myTickets.push(parentNetworkTicket);
		}

		indices[ticket.ticket_id] = index;
		return indices;
	}, networkTicketIndices);

	//append supplier tickets to correct parent network ticket
	supplierTickets.forEach(ticket => {
		const ntt = ticket.network_ticket_id;
		const index = networkTicketIndices[ntt];
		if (index >= 0) {
			networkTickets[index].supplierTickets.push(ticket);
		}

		if (ticketIsAssignedToUser(ticket, userDetails)) {
			myTickets.push(ticket);
		}
	});

	//append cleint tickets to correct parent network ticket
	clientTickets.forEach(ticket => {
		const ntt = ticket.network_ticket_id;
		const index = networkTicketIndices[ntt];

		if (index >= 0) {
			networkTickets[index].clientTickets.push(ticket);
		}

		if (ticketIsAssignedToUser(ticket, userDetails)) {
			myTickets.push(ticket);
		}
	});

	return { networkTickets, clientTickets, supplierTickets, myTickets };
};
