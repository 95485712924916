import React from 'react';
import { ChakraProvider, ColorModeScript, useColorMode } from '@chakra-ui/react';
import { appTheme } from 'equiano/utils/theme';
import { MUI_THEME_IDENTIFIER, getMuiTheme } from 'equiano/utils/theme/mui-theme-provider';

interface Props {
	children: React.ReactNode;
}

const AppChakraProvider = ({ children }: Props) => {
	const { colorMode } = useColorMode();

	return (
		<>
			<ColorModeScript initialColorMode={appTheme.config.initialColorMode} />
			<ChakraProvider
				theme={{
					...appTheme,
					[MUI_THEME_IDENTIFIER]: getMuiTheme(colorMode),
				}}
			>
				{children}
			</ChakraProvider>
		</>
	);
};

export default AppChakraProvider;
