import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastProvider } from 'react-toast-notifications';
import { BrowserRouter as Router } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// import ErrorBoundary from './components/ErrorBoundary';
import App from './App';
import AppProviders from 'equiano/utils/providers';

const root = ReactDOM.createRoot(document.getElementById('root'));

// import { Buffer } from 'buffer';

// declare global {
// 	interface Window {
// 		Buffer: any;
// 	}
// }

// window.Buffer = Buffer;

root.render(
	<AppProviders>
		{/* <ErrorBoundary> */}
		<Router>
			<ToastProvider>
				<App />
			</ToastProvider>
		</Router>
		{/* </ErrorBoundary> */}
	</AppProviders>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
