import React from 'react';
import ReactQueryClientProvider from './react-query-provider';
import ErrorBoundary from 'equiano/utils/pages/ErrorBoundary';
import AppChakraProvider from './app-chakra-provider';
import ReduxProvider from './redux-provider';
// import CustomMuiThemeProvider from 'equiano/utils/theme/mui-theme-provider';

interface Props {
	children: React.ReactNode;
}

const AppProviders = ({ children }: Props) => {
	return (
		<ErrorBoundary>
			<ReduxProvider>
				<ReactQueryClientProvider>
					<AppChakraProvider>
						{children}
						{/* <CustomMuiThemeProvider>{children}</CustomMuiThemeProvider> */}
					</AppChakraProvider>
				</ReactQueryClientProvider>
			</ReduxProvider>
		</ErrorBoundary>
	);
};

export default AppProviders;
