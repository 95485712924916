import { lazy } from 'react';
import type { RouteObject } from './interfaces';

const DiagramsPage = lazy(() => import('engineering/diagrams/DiagramsPage'));

const StockPage = lazy(() => import('engineering/stock/StockPage'));
const NewStockDashboard = lazy(() => import('engineering/stock/budget-approval/BudgetApproval'));

const PopsPage = lazy(() => import('engineering/field/pops/PopsPage'));
const SinglePopPage = lazy(() => import('engineering/field/pops/SinglePopPage'));
const PopAudit = lazy(() => import('engineering/field/pops/PopAudit'));
const NewBOMSPage = lazy(() => import('engineering/network-architect/boms/NewBOM.js'));
const FindEngineers = lazy(() => import('engineering/FindEngineer'));

const EquipmentPage = lazy(() => import('components/Engineering/equipment/EquipmentPage'));

const CapacityReports = lazy(() => import('components/topology/Capacity/reports/CapacityReports'));
const CapacitySupplierReports = lazy(() => import('components/topology/Capacity/reports/supplier-reports/supplier-main-report'));
const CapacityDiagramPage = lazy(() => import('components/topology/CapacityDiagramPage'));
const SRPage = lazy(() => import('components/topology/Capacity/POCapacity/SingleSRPage'));

// const SRPage = lazy(() => import('./components/topology/Capacity/POCapacity/SingleSRPage'));

// const AllPOsPage = lazy(() => import('./components/POS/AllPOsPage'));
// const SinglePOPage = lazy(() => import('./components/POS/SinglePOPage'));

// const CapacityReports = lazy(() => import('./components/topology/Capacity/reports/CapacityReports'));

const Links = lazy(() => import('engineering/network-admin/links/Links'));
const BidsdTest = lazy(() => import('engineering/diagrams/bidsd/Diag'));

const WpnsReportPage = lazy(() => import('engineering/stock/wpns-report'));

const ENGINEERING_ROUTES: RouteObject[] = [
	/* CAPACITY */
	{ path: '/capacity/reports', element: CapacityReports },
	{ path: '/capacity/supplier-reports', element: CapacitySupplierReports},
	{ path: '/capacity', element: CapacityDiagramPage },
	{ path: '/srs/:sr', element: SRPage },

	{ path: '/find-engineers/:type?', element: FindEngineers },

	{ path: '/links', element: Links },

	{ path: '/equipment', element: EquipmentPage },
	{ path: '/diagrams/bidsd', element: BidsdTest },
	{ path: '/diagrams', element: DiagramsPage },
	{ path: '/bidsd-diagrams', element: BidsdTest },
	{ path: '/optical_link/:optical_link_id', element: DiagramsPage },

	/* STOCK */
	{ path: '/stock/wpns-report', element: WpnsReportPage },
	{ path: '/stock/stock-dashboard', element: NewStockDashboard },
	{ path: '/stock/:stock_id', element: StockPage },
	{ path: '/stocking_point/:stock_id', element: StockPage },
	{ path: '/stock', element: StockPage },

	/* BOMS */
	{ path: '/boms/new', element: NewBOMSPage },

	/* POP & POP AUDIT */
	{ path: '/pops', element: PopsPage },
	{ path: '/pop/:pop_id', element: SinglePopPage },
	{ path: '/pop_audit', element: PopAudit },

	// { path: '/pos/:po', element: SinglePOPage },
	// { path: '/pos', element: AllPOsPage },
];

export default ENGINEERING_ROUTES;
