import { lazy } from 'react';
import type { RouteObject } from './types';

const APP_ROUTES: RouteObject[] = [
	//add routes here

	{ path: '/equiano/logout', element: lazy(() => import('equiano/pages/Logout')) },

	{
		path: '/equiano/capacity/new-request',
		element: lazy(() => import('equiano/pages/NewCapacityRequest')),
	},
	{
		path: '/equiano/capacity/requests',
		element: lazy(() => import('equiano/pages/CapacityRequests')),
	},
	{
		path: '/equiano/capacity/activations',
		element: lazy(() => import('equiano/pages/activation')),
	},
	{
		path: '/equiano/capacity/activations/new',
		element: lazy(() => import('equiano/pages/activation/activation-request')),
	},
	{
		path: '/equiano/tickets/new',
		element: lazy(() => import('equiano/pages/ticketing/new-ticket')),
	},
	{
		path: '/equiano/tickets',
		element: lazy(() => import('equiano/pages/ticketing')),
	},
	{
		path: '/equiano/',
		// element: lazy(() => import('equiano/pages/Home')),
		element: lazy(() => import('equiano/pages/CapacityRequests')),
	},
];

export default APP_ROUTES;
