import React, { useContext, useState } from 'react';
import { css } from '@emotion/core';
import BarLoader from 'react-spinners/BarLoader';
// import logo from "../../images/WIOCC Hub Logo 2022-min.png";
import connectivityLogo from '../../images/Hub Logo 2022.png';
import oadcLogo from '../../images/OADC Logo Master No Tag May 2022.png';
import Context from 'store/context';
import Box from '@mui/material/Box';
import { ProfileType } from 'store/interfaces';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
	border-color: white;
`;

export default function MyLoader() {
	const { globalState } = useContext(Context);
	const [loading] = useState(true);

	const inConnectivity = globalState.profile === ProfileType.CONNECTIVITY;

	return (
		<Box
			// bg="primary"
			// bg={inConnectivity?"primary"}
			style={{
				width: '100%',
				height: '100%',
				backgroundColor: inConnectivity ? '#20368b' : '#7da243',
				position: 'fixed',
				textAlign: 'center',
				margin: 'auto',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexFlow: 'column',
			}}
		>
			<div>
				{/* <img src="/images/new_logo_caption.png" alt="Logo" height="45px" width="181px" /> */}
				<img
					src={inConnectivity ? connectivityLogo : oadcLogo}
					alt="Logo"
					height="45px"
					width="181px"
					style={{
						objectFit: 'contain',
						aspectRatio: 3 / 2,
					}}
				/>
			</div>
			<div>
				<BarLoader css={override} width={150} color={'#ffffff'} loading={loading} />
			</div>
		</Box>
	);
}
